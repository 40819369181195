import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const openCookieConsentSettings = (e) => {
    window.cookieconsent.run({
        "notice_banner_type": "simple", //simple, headline, interstitial
        "consent_type": "express",
        "palette": "dark",
        "language": "de",
        "page_load_consent_levels": ["strictly-necessary"],
        "notice_banner_reject_button_hide": false,
        "preferences_center_close_button_hide": false,
        "website_name": "Physiotherapie Mitbewegt",
        "website_privacy_policy_url": "https://physiotherapie-mitbewegt.de/datenschutz"
    });
    e.target.click()
}

const DatenschutzEinstellungenPage = () => (
    <Layout>
        <Seo title="Datenschutz Einstellungen" />
        <section className="section">
            <div className="container is-max-desktop">
                <div className="content">
                    <h1 className="title is-1">Datenschutz Einstellungen</h1>
                    <p>Mit einem Klick auf den Button können Sie ihre getroffenen Cookie Einstellungen anpassen:</p>
                    <button onClick={openCookieConsentSettings} className="button is-dark is-rounded" id="open_preferences_center">Cookie Einstellungen ändern</button>
                </div>
            </div>
        </section>

    </Layout>
)

export default DatenschutzEinstellungenPage
